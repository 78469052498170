import { Theme } from "@cloudscape-design/components/theming";

export const brandTheme: Theme = {
  tokens: {
    // Values are applied globally, except for visual contexts
    colorBackgroundLayoutMain: {
      // Specify value for light and dark mode
      light: "#f3f2f1",
      dark: "#f3f2f1",
    },
    // Shorter syntax to apply the same value for both light and dark mode
    // colorTextAccent: "#0073bb",
    // fontFamilyBase: "'Helvetica Neue', Roboto, Arial, sans-serif",
    borderRadiusButton: "0px",
    // borderRadiusContainer: "4px",
  },
  contexts: {
    // Values for visual contexts. Unless specified, default values will be applied
    alert: {
      tokens: {
        // colorTextBodyDefault: "#129446",
      },
    },
    header: {
      tokens: {
        colorBackgroundLayoutMain: "#f3f2f1",
        // fontSizeHeadingL:"50px"
        // colorTextHeadingDefault: "#000000",
        // borderRadiusButton: "4px",
      },
    },
  },
};
