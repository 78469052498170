// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0
import React from 'react';
import { Button, Header, HeaderProps, SpaceBetween, ButtonDropdown } from '@cloudscape-design/components';
import { InfoLink } from './info-link';
import DateRangePicker from "@cloudscape-design/components/date-range-picker";


interface FullPageHeaderProps extends HeaderProps {
  title?: string;
  createButtonText?: string;
  extraActions?: React.ReactNode;
  selectedItemsCount: number;
  onInfoLinkClick?: () => void;
}

export function FullPageHeader({
  title = 'Invoices',
  createButtonText = 'New Invoice',
  extraActions = null,
  selectedItemsCount,
  onInfoLinkClick,
  ...props
}: FullPageHeaderProps) {
  const isOnlyOneSelected = selectedItemsCount === 1;
  const [value, setValue] = React.useState(undefined);

  return (
    <Header
      variant="awsui-h1-sticky"
      info={onInfoLinkClick && <InfoLink onFollow={onInfoLinkClick} />}
      actions={
        <SpaceBetween size="xs" direction="horizontal">
          {extraActions}
          
          <ButtonDropdown
            items={[
              { text: "Print", id: "rm", disabled: false },
              { text: "Print Ticket", id: "rm", disabled: false },
              { text: "Print with Discount", id: "mv", disabled: false },
              { text: "Print with Package Bill", id: "mv", disabled: false },
              { text: "Print Original", id: "rn", disabled: false },
              { text: "Print for Insurance", id: "rn", disabled: true }
              
            ]}
          >
            Print 
          </ButtonDropdown>
          <ButtonDropdown
            items={[
              { text: "Edit", id: "rm", disabled: false },
              { text: "Department Wise Discount", id: "mv", disabled: false },
              { text: "Send Email", id: "mv", disabled: false }
              
            ]}
          >
            Action 
          </ButtonDropdown>
          <Button data-testid="header-btn-create" variant="primary">
            {createButtonText}
          </Button>
        </SpaceBetween>
      }
      {...props}
    >
      {title}
    </Header>
  );
}