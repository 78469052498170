import Box from "@cloudscape-design/components/box";
// Table Header
export const tableHeader = "This is the table view  edited"

// Table Filter Placeholder
export const filterPlaceholder = "This is the filtering placeholder"

// Column Definitions
export const columnDefinitions = [
    {
      id: "created_at",
      header: "Bill Date/Time",
      cell: e => e.created_at,
      sortingField: "created_at",
      isRowHeader: true
    },
    {
      id: "value",
      header: "Bill No",
      cell: e => e.bill_no,
      sortingField: "bill_no"
    },
    { 
      id: "Patient ID", 
      header: "Patient ID", 
      cell: e => e.id 
    },
    {
      id: "Patient Name",
      header: "Patient Name",
      cell: e => e.name
    },
    {
      id: "Total Amt.",
      header: "Total Amt.",
      cell: item => (
        <Box float="right">{ 'Rs. '+item.master_total || "-"}</Box>
      ),
    },
    {
      id: "Discount",
      header: "Discount",
      cell: e => e.master_discount
    },
    {
      id: "Net Amt.",
      header: "Net Amt.",
      cell: e => e.amt_bd
    },
    {
      id: "Refund",
      header: "Refund",
      cell: e => e.refund
    },
    {
      id: "Balance",
      header: "Balance",
      cell: e => e.balance_amount
    },
    {
      id: "Rate Type",
      header: "Rate Type",
      cell: e => e.pannel_name
    },
  ];

// Column Display
export const columnDisplay=[
  { id: "Bill Date/Time", visible: true },
  { id: "Bill No", visible: true },
  { id: "Patient ID", visible: true },
  { id: "Patient Name", visible: true },
  { id: "Total Amt.", visible: true },
  { id: "Discount", visible: true },
  { id: "Net Amt", visible: true },
  { id: "Refund", visible: true },
  { id: "Balance", visible: true },
  { id: "Rate Type", visible: false }

]

// Table Items
export const columnItems=[
  {
  "created_at": "2024-03-21 13:23:16",
  "bill_no": "A8081-0215834",
  "amt_bd": 840,
  "master_discount": 0,
  "master_total": 840,
  "refund": 0,
  "balance_amount": 0,
  "id": 7632991,
  "DT_RowId": 1171404,
  "pannel_name": "General-Non Cat",
  "name": "MINU  MANDAL",
  "gender": "40 Y/Female",
  "cretor": "Surendra Maharjan"
  },
  {
  "created_at": "2024-03-21 13:23:09",
  "bill_no": "A8081-0215833",
  "amt_bd": 7800,
  "master_discount": 0,
  "master_total": 7800,
  "refund": 0,
  "balance_amount": 0,
  "id": 8260517,
  "DT_RowId": 1171403,
  "pannel_name": "General-Non Cat",
  "name": "SANJITA  SAPKOTA",
  "gender": "20 Y/Female",
  "cretor": "Sapna Bhatta "
  },
  {
  "created_at": "2024-03-21 13:22:56",
  "bill_no": "A8081-0215832",
  "amt_bd": 9400,
  "master_discount": 0,
  "master_total": 9400,
  "refund": 0,
  "balance_amount": 0,
  "id": 8260516,
  "DT_RowId": 1171402,
  "pannel_name": "General-Non Cat",
  "name": "SAMJHANA  THAPA",
  "gender": "25 Y/Female",
  "cretor": "Sapna Bhatta "
  },
  {
  "created_at": "2024-03-21 13:22:26",
  "bill_no": "A8081-0215831",
  "amt_bd": 2520,
  "master_discount": 0,
  "master_total": 2520,
  "refund": 0,
  "balance_amount": 0,
  "id": 8055594,
  "DT_RowId": 1171401,
  "pannel_name": "General-Non Cat",
  "name": "URMILA  RANA",
  "gender": "28 Y/Female",
  "cretor": "Ayushma Maharjan"
  },
  {
  "created_at": "2024-03-21 13:22:13",
  "bill_no": "A8081-0215830",
  "amt_bd": 1255,
  "master_discount": 0,
  "master_total": 1255,
  "refund": 0,
  "balance_amount": 0,
  "id": 8055594,
  "DT_RowId": 1171400,
  "pannel_name": "General-Non Cat",
  "name": "URMILA  RANA",
  "gender": "28 Y/Female",
  "cretor": "Ayushma Maharjan"
  },
  {
  "created_at": "2024-03-21 13:21:40",
  "bill_no": "A8081-0215829",
  "amt_bd": 1081,
  "master_discount": 0,
  "master_total": 1081,
  "refund": 0,
  "balance_amount": 0,
  "id": 8259475,
  "DT_RowId": 1171399,
  "pannel_name": "General-Non Cat",
  "name": "PRATIMA  LAMA",
  "gender": "40 Y/Female",
  "cretor": "Surendra Shakya"
  },
  {
  "created_at": "2024-03-21 13:21:32",
  "bill_no": "A8081-0215828",
  "amt_bd": 1081,
  "master_discount": 1081,
  "master_total": 0,
  "refund": 0,
  "balance_amount": 0,
  "id": 8072688,
  "DT_RowId": 1171398,
  "pannel_name": "Norvic Staff",
  "name": "RAJENDRA BAHADUR SINGH",
  "gender": "69 Y/Male",
  "cretor": "Sabina Shahi(1329)"
  },
  {
  "created_at": "2024-03-21 13:19:17",
  "bill_no": "A8081-0215827",
  "amt_bd": 1081,
  "master_discount": 0,
  "master_total": 1081,
  "refund": 0,
  "balance_amount": 0,
  "id": 8238515,
  "DT_RowId": 1171397,
  "pannel_name": "General-Non Cat",
  "name": "POONAM  SHRESTHA",
  "gender": "42 Y/Female",
  "cretor": "Ayushma Maharjan"
  },
  {
  "created_at": "2024-03-21 13:19:14",
  "bill_no": "A8081-0215826",
  "amt_bd": 2415,
  "master_discount": 0,
  "master_total": 2415,
  "refund": 0,
  "balance_amount": 0,
  "id": 8089587,
  "DT_RowId": 1171396,
  "pannel_name": "General-Non Cat",
  "name": "JAGADISH  YADAV",
  "gender": "63 Y/Male",
  "cretor": "Surendra Shakya"
  },
  {
  "created_at": "2024-03-21 13:19:03",
  "bill_no": "A8081-0215825",
  "amt_bd": 16410,
  "master_discount": 0,
  "master_total": 16410,
  "refund": 0,
  "balance_amount": 0,
  "id": 8220483,
  "DT_RowId": 1171395,
  "pannel_name": "General-Non Cat",
  "name": "ASHOK KUMAR PARAKH",
  "gender": "72 Y/Male",
  "cretor": "Sandhya Khadka"
  },
  {
  "created_at": "2024-03-21 13:19:00",
  "bill_no": "A8081-0215824",
  "amt_bd": 2850,
  "master_discount": 0,
  "master_total": 2850,
  "refund": 0,
  "balance_amount": 0,
  "id": 8191587,
  "DT_RowId": 1171394,
  "pannel_name": "General-Non Cat",
  "name": "SHREESH  PARAJULI",
  "gender": "34 Y/Male",
  "cretor": "Sabina Shahi(1329)"
  },
  {
  "created_at": "2024-03-21 13:18:25",
  "bill_no": "A8081-0215823",
  "amt_bd": 3500,
  "master_discount": 0,
  "master_total": 3500,
  "refund": 0,
  "balance_amount": 0,
  "id": 7132910,
  "DT_RowId": 1171393,
  "pannel_name": "General-Non Cat",
  "name": "LEESA  MANANDHAR",
  "gender": "50 Y/Female",
  "cretor": "Nabina Shrestha"
  },
  {
  "created_at": "2024-03-21 13:17:55",
  "bill_no": "A8081-0215821",
  "amt_bd": 2045,
  "master_discount": 0,
  "master_total": 2045,
  "refund": 0,
  "balance_amount": 0,
  "id": 7885805,
  "DT_RowId": 1171390,
  "pannel_name": "General-Non Cat",
  "name": " USHA  GURUNG",
  "gender": "33 Y/Female",
  "cretor": "Ayushma Maharjan"
  },
  {
  "created_at": "2024-03-21 13:17:34",
  "bill_no": "A8081-0215820",
  "amt_bd": 2900,
  "master_discount": 0,
  "master_total": 2900,
  "refund": 0,
  "balance_amount": 0,
  "id": 8260515,
  "DT_RowId": 1171389,
  "pannel_name": "General-Non Cat",
  "name": "KRISHNA  BUDHA",
  "gender": "23 Y/Female",
  "cretor": "Sapna Bhatta "
  },
  {
  "created_at": "2024-03-21 13:17:04",
  "bill_no": "A8081-0215819",
  "amt_bd": 6720,
  "master_discount": 0,
  "master_total": 6720,
  "refund": 0,
  "balance_amount": 0,
  "id": 7497306,
  "DT_RowId": 1171388,
  "pannel_name": "General-Non Cat",
  "name": "BANDANA  KARKI",
  "gender": "43 Y/Female",
  "cretor": "Ayushma Maharjan"
  },
  {
  "created_at": "2024-03-21 13:16:45",
  "bill_no": "A8081-0215818",
  "amt_bd": 18370,
  "master_discount": 0,
  "master_total": 18370,
  "refund": 0,
  "balance_amount": 0,
  "id": 8260350,
  "DT_RowId": 1171387,
  "pannel_name": "General-Non Cat",
  "name": "THULI MAYA TAMANG",
  "gender": "56 Y/Female",
  "cretor": "Sabina Shahi(1329)"
  },
  {
  "created_at": "2024-03-21 13:16:03",
  "bill_no": "A8081-0215817",
  "amt_bd": 1081,
  "master_discount": 0,
  "master_total": 1081,
  "refund": 0,
  "balance_amount": 0,
  "id": 7808981,
  "DT_RowId": 1171386,
  "pannel_name": "General-Non Cat",
  "name": "RANJU  HAMAL",
  "gender": "42 Y/Female",
  "cretor": "Surendra Shakya"
  },
  {
  "created_at": "2024-03-21 13:15:47",
  "bill_no": "A8081-0215816",
  "amt_bd": 1081,
  "master_discount": 0,
  "master_total": 1081,
  "refund": 0,
  "balance_amount": 0,
  "id": 7696958,
  "DT_RowId": 1171385,
  "pannel_name": "General-Non Cat",
  "name": "ASHOK RATNA STHAPIT",
  "gender": "67 Y/Male",
  "cretor": "Raj Thapa"
  },
  {
  "created_at": "2024-03-21 13:15:22",
  "bill_no": "A8081-0215815",
  "amt_bd": 11355,
  "master_discount": 0,
  "master_total": 11355,
  "refund": 0,
  "balance_amount": 0,
  "id": 8260480,
  "DT_RowId": 1171384,
  "pannel_name": "General-Non Cat",
  "name": "RAJ BABU KC",
  "gender": "18 Y/Male",
  "cretor": "Ayushma Maharjan"
  },
  {
  "created_at": "2024-03-21 13:14:50",
  "bill_no": "A8081-0215814",
  "amt_bd": 1335,
  "master_discount": 0,
  "master_total": 1335,
  "refund": 0,
  "balance_amount": 0,
  "id": 8197078,
  "DT_RowId": 1171383,
  "pannel_name": "General-Non Cat",
  "name": "NAMRATA  NEPALI",
  "gender": "22 Y/Female",
  "cretor": "Nabina Shrestha"
  },
  {
  "created_at": "2024-03-21 13:14:38",
  "bill_no": "A8081-0215813",
  "amt_bd": 1181,
  "master_discount": 0,
  "master_total": 1181,
  "refund": 0,
  "balance_amount": 0,
  "id": 8260514,
  "DT_RowId": 1171382,
  "pannel_name": "General-Non Cat",
  "name": "SUNIL  PHUYAL",
  "gender": "30 Y/Male",
  "cretor": "Surendra Shakya"
  },
  {
  "created_at": "2024-03-21 13:14:22",
  "bill_no": "A8081-0215812",
  "amt_bd": 8595,
  "master_discount": 2404.1,
  "master_total": 6191,
  "refund": 0,
  "balance_amount": 0,
  "id": 7559270,
  "DT_RowId": 1171381,
  "pannel_name": "Staff Family",
  "name": "SWASTIKA  GAUTAM",
  "gender": "70 Y/Female",
  "cretor": "Surendra Maharjan"
  },
  {
  "created_at": "2024-03-21 13:13:36",
  "bill_no": "A8081-0215811",
  "amt_bd": 14365,
  "master_discount": 0,
  "master_total": 14365,
  "refund": 0,
  "balance_amount": 0,
  "id": 8231709,
  "DT_RowId": 1171380,
  "pannel_name": "General-Non Cat",
  "name": "ARPANA  SINGH",
  "gender": "53 Y/Female",
  "cretor": "Raj Thapa"
  },
  {
  "created_at": "2024-03-21 13:13:19",
  "bill_no": "A8081-0215810",
  "amt_bd": 1181,
  "master_discount": 0,
  "master_total": 1181,
  "refund": 0,
  "balance_amount": 0,
  "id": 8260513,
  "DT_RowId": 1171379,
  "pannel_name": "General-Non Cat",
  "name": "AMRITA  ADHIKARI",
  "gender": "24 Y/Female",
  "cretor": "Surendra Shakya"
  },
  {
  "created_at": "2024-03-21 13:13:12",
  "bill_no": "A8081-0215809",
  "amt_bd": 14365,
  "master_discount": 0,
  "master_total": 14365,
  "refund": 0,
  "balance_amount": 0,
  "id": 8100159,
  "DT_RowId": 1171378,
  "pannel_name": "General-Non Cat",
  "name": "PUSHPA RAJ PANDEY",
  "gender": "42 Y/Male",
  "cretor": "Raj Thapa"
  }
  ]

// Button Label
export const buttonLabel = "Button Label"

// Button Options
export const buttonOptions = [
    { text: "View", id: "vw", disabled: false },
    { text: "Delete", id: "rm", disabled: false },
    { text: "Rename", id: "rn", disabled: true },
  ]

export const breadcrumbGroup = [
  { text: "Homepage", href: "/" },
  { text: "Table View", href: "#/tableview" }
]