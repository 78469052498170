// Page Header
export const pageHeader = "This is the homepage header"

// Description
export const pageDescription = "This is the homepage description"

// Alert
export const alert = "This is the alert"

// Container Header
export const containerHeader = "This is the container header"

// Container content
export const containerDescription = "This is the container description"

// Container description
export const containerContent = "This is the container content"